<span class="green-color" *ngIf="blockConversion; else noblockconversion">
  {{
    (
      (blockConversion.price[currency] > -1 ? blockConversion.price[currency] : null) ??
      (blockConversion.price['USD']    > -1 ? blockConversion.price['USD'] * blockConversion.exchangeRates['USD' + currency] : null) ?? 0
    ) * value / 100000000 | fiatCurrency : digitsInfo : currency
  }}
</span>

<ng-template #noblockconversion>
  <span class="green-color" *ngIf="(conversions$ | async) as conversions">
    {{ (conversions[currency] > -1 ? conversions[currency] : 0) * value / 100000000 | fiatCurrency : digitsInfo : currency }}
  </span>
</ng-template>