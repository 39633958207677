<ng-template [ngIf]="channel" [ngIfElse]="default">
  <div>
    <div class="badge-positioner">
      <a [routerLink]="['/lightning/channel' | relativeUrl, channel.id]">
        <span 
          *ngIf="label"
          class="badge badge-pill badge-warning"
        >{{ label }}</span>
      </a>
    </div>
    &nbsp;
  </div>
</ng-template>

<ng-template #default>
  <span
    *ngIf="label"
    class="badge badge-pill badge-warning"
  >{{ label }}</span>
</ng-template>