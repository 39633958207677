<div class="container-xl" [class.liquid-address]="network === 'liquid' || network === 'liquidtestnet'">
  <div class="title-address">
    <h1 i18n="shared.address">Address</h1>
    <div class="tx-link">
      <app-truncate [text]="addressString" [lastChars]="8" [link]="['/address/' | relativeUrl, addressString]">
        <app-clipboard [text]="addressString"></app-clipboard>
      </app-truncate>
    </div>
  </div>

  <div class="clearfix"></div>

  <ng-template [ngIf]="!isLoadingAddress && !error">
    <div class="box">

      <div class="row">
        <div class="col-md">
          <table class="table table-borderless table-striped address-table">
            <tbody>
              <tr *ngIf="addressInfo && addressInfo.unconfidential">
                <td i18n="address.unconfidential">Unconfidential</td>
                <td>
                  <app-truncate [text]="addressInfo.unconfidential" [lastChars]="8" [link]="['/address/' | relativeUrl, addressInfo.unconfidential]">
                    <app-clipboard [text]="addressInfo.unconfidential"></app-clipboard>
                  </app-truncate>
                </td>
              </tr>
              <ng-template [ngIf]="!address.electrum">
                <tr>
                  <td i18n="address.total-received">Total received</td>
                  <td *ngIf="address.chain_stats.funded_txo_sum !== undefined; else confidentialTd"><app-amount [satoshis]="received" [noFiat]="true"></app-amount></td>
                </tr>
                <tr>
                  <td i18n="address.total-sent">Total sent</td>
                  <td *ngIf="address.chain_stats.spent_txo_sum !== undefined; else confidentialTd"><app-amount [satoshis]="sent" [noFiat]="true"></app-amount></td>
                </tr>
              </ng-template>
              <tr>
                <td i18n="address.balance">Balance</td>
                <td *ngIf="address.chain_stats.funded_txo_sum !== undefined; else confidentialTd"><app-amount [satoshis]="received - sent" [noFiat]="true"></app-amount> <span class="fiat"><app-fiat [value]="received - sent"></app-fiat></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-100 d-block d-md-none"></div>
        <div class="col-md qrcode-col">
          <div class="qr-wrapper">
            <app-qrcode [data]="address.address"></app-qrcode>
          </div>
        </div>
      </div>

    </div>

    <br>
    <div class="title-tx">
      <h2 class="text-left">
        <ng-template [ngIf]="!transactions?.length">&nbsp;</ng-template>
        <ng-template i18n="X of X Address Transaction" [ngIf]="transactions?.length === 1">{{ (transactions?.length | number) || '?' }} of {{ txCount | number }} transaction</ng-template>
        <ng-template i18n="X of X Address Transactions (Plural)" [ngIf]="transactions?.length > 1">{{ (transactions?.length | number) || '?' }} of {{ txCount | number }} transactions</ng-template>
      </h2>
    </div>

    <app-transactions-list [transactions]="transactions" [showConfirmations]="true" [address]="address.address" (loadMore)="loadMore()"></app-transactions-list>

    <div class="text-center">
      <ng-template [ngIf]="isLoadingTransactions">

        <ng-container *ngIf="addressLoadingStatus$ as addressLoadingStatus">
          <div class="header-bg box" style="padding: 12px; margin-bottom: 10px;">
            <div class="progress progress-dark">
              <div class="progress-bar progress-light" role="progressbar" [ngStyle]="{'width': addressLoadingStatus + '%' }"></div>
            </div>
          </div>
        </ng-container>

        <div class="header-bg box">
          <div class="row" style="height: 107px;">
            <div class="col-sm">
              <span class="skeleton-loader"></span>
            </div>
            <div class="col-sm">
              <span class="skeleton-loader"></span>
            </div>
          </div>
        </div>

      </ng-template>

      <ng-template [ngIf]="retryLoadMore">
        <br>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="loadMore()"><fa-icon [icon]="['fas', 'redo-alt']" [fixedWidth]="true"></fa-icon></button>
      </ng-template>
    </div>

  </ng-template>

  <ng-template [ngIf]="isLoadingAddress && !error">

    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td colspan="2"><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td colspan="2"><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td colspan="2"><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="w-100 d-block d-md-none"></div>
        <div class="col">

        </div>
      </div>
    </div>

  </ng-template>

  <ng-template [ngIf]="error">
    <br>
    <div class="text-center">
      <span i18n="address.error.loading-address-data">Error loading address data.</span>
      <br>
      <ng-template #displayServerError><i class="small">({{ error.error }})</i></ng-template>
      <ng-template [ngIf]="error.status === 413 || error.status === 405 || error.status === 504" [ngIfElse]="displayServerError">
        <ng-container i18n="Electrum server limit exceeded error">
          <i>There many transactions on this address, more than your backend can handle. See more on <a href="/docs/faq#address-lookup-issues">setting up a stronger backend</a>.</i>
          <br><br>
          Consider viewing this address on the official Mempool website instead:
        </ng-container>
        <br>
        <a href="https://mempool.space/address/{{ addressString }}" target="_blank">https://mempool.space/address/{{ addressString }}</a>
        <br>
        <a href="http://mempoolhqx4isw62xs7abwphsq7ldayuidyx2v2oethdhhj6mlo2r6ad.onion/address/{{ addressString }}" target="_blank">http://mempoolhqx4isw62xs7abwphsq7ldayuidyx2v2oethdhhj6mlo2r6ad.onion/address/{{ addressString }}</a>
        <br><br>
        <i class="small">({{ error.error }})</i>
      </ng-template>
    </div>
  </ng-template>

</div>

<br>

<ng-template #confidentialTd>
  <td i18n="shared.confidential">Confidential</td>
</ng-template>

<ng-template #headerLoader>
  <div class="header-bg box" style="padding: 10px; margin-bottom: 10px;">
    <span class="skeleton-loader"></span>
  </div>
</ng-template>
