<ng-container [ngSwitch]="name">
  <ng-container *ngSwitchCase="'officialMempoolSpace'">
    <svg [class]="class" [style]="style" [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M163.658 113.263C161.089 113.263 158.992 111.146 158.992 108.535C158.992 105.966 161.048 103.951 163.658 103.951C166.269 103.951 168.325 105.966 168.325 108.535C168.325 111.125 166.228 113.263 163.658 113.263Z" fill="#9857FF"/>
      <path d="M189.767 113.263C183.867 113.263 177.803 111.392 174.637 108.617L174 108.062L177.104 102.594L178.05 103.334C180.434 105.205 185.08 107.096 190.054 107.096C195.79 107.096 198.565 105.554 198.565 102.368C198.565 99.3461 195.194 98.5856 189.664 97.6194C183.312 96.5094 175.398 95.1115 175.398 86.6012C175.398 79.8999 181.359 75.5625 190.589 75.5625C195.584 75.5625 200.785 76.9192 203.807 79.0365L204.567 79.571L201.484 85.2034L200.559 84.5867C197.804 82.7572 194.269 81.7499 190.609 81.7499C185.368 81.7499 182.366 83.4767 182.366 86.4779C182.366 89.7052 185.84 90.548 191.576 91.5759C197.784 92.6859 205.513 94.0632 205.513 102.224C205.513 105.575 204.012 108.37 201.175 110.323C198.421 112.255 194.474 113.263 189.767 113.263Z" fill="#9857FF"/>
      <path d="M212.316 125.863V75.9733H218.997V80.8451C222.122 77.4945 226.541 75.7061 231.722 75.7061C236.984 75.7061 241.774 77.5356 245.186 80.8862C248.66 84.278 250.572 89.0882 250.572 94.4123C250.572 99.7569 248.66 104.588 245.206 108C241.774 111.392 237.005 113.242 231.742 113.242C226.624 113.242 222.327 111.536 219.162 108.247V125.843H212.316V125.863ZM231.434 81.8729C228.001 81.8729 224.876 83.1063 222.656 85.3264C220.354 87.6287 219.141 90.7738 219.141 94.4123C219.141 101.792 224.198 106.952 231.454 106.952C238.69 106.952 243.768 101.792 243.768 94.4123C243.768 90.7738 242.555 87.6287 240.252 85.3264C237.991 83.1063 234.867 81.8729 231.434 81.8729Z" fill="#9857FF"/>
      <path d="M269.194 113.262C260.643 113.262 255.113 108.884 255.113 102.1C255.113 98.8936 256.285 96.2212 258.526 94.3712C261.054 92.2744 264.878 91.2055 269.893 91.2055H280.233V90.1777C280.233 84.7714 277.17 82.0168 271.147 82.0168C266.995 82.0168 262.781 83.4558 259.882 85.8814L258.916 86.7037L255.956 81.1329L256.593 80.5985C260.417 77.4328 265.823 75.7061 271.846 75.7061C281.734 75.7061 287.181 80.8862 287.181 90.301V112.975H280.5V109.398C277.89 111.906 273.984 113.262 269.194 113.262ZM270.181 96.6735C262.925 96.6735 262.082 99.613 262.082 101.813C262.082 105.348 264.98 107.363 270.037 107.363H270.058C270.181 107.363 270.304 107.363 270.448 107.363C275.115 107.363 278.547 105.225 280.377 101.175V96.6735H270.181V96.6735Z" fill="#9857FF"/>
      <path d="M313.658 112.995C302.742 112.995 294.828 105.184 294.828 94.4123C294.828 89.1088 296.781 84.3191 300.337 80.9274C303.852 77.5561 308.786 75.7061 314.233 75.7061C320.77 75.7061 326.053 78.2961 329.137 83.0241L329.712 83.8875L324.347 87.3204L323.792 86.4776C321.777 83.4558 318.303 81.729 314.254 81.729C310.656 81.729 307.552 82.9213 305.291 85.1825C303.01 87.4643 301.797 90.6094 301.797 94.2684C301.797 97.9685 303.01 101.134 305.332 103.395C307.573 105.595 310.739 106.808 314.233 106.808C318.303 106.808 321.695 105.163 323.771 102.183L324.326 101.381L329.671 104.793L329.137 105.657C326.238 110.323 320.811 112.995 314.254 112.995C314.048 112.995 313.863 112.995 313.658 112.995Z" fill="#9857FF"/>
      <path d="M352.118 113.386C346.506 113.386 341.429 111.516 337.852 108.103C334.255 104.691 332.281 99.881 332.281 94.5363C332.281 83.7031 340.031 75.8301 350.7 75.8301C361.204 75.8301 368.83 83.5387 368.995 94.228C368.995 94.8447 368.995 96.9414 368.995 96.9414H339.373C340.36 103.047 345.396 107.076 352.241 107.076C356.414 107.076 360.032 105.596 362.417 102.903L363.177 102.039L367.206 106.603L366.61 107.281C363.218 111.228 358.059 113.386 352.118 113.386ZM362.335 91.3295C361.368 85.6354 356.805 81.7297 350.864 81.7297C344.964 81.7297 340.483 85.5327 339.414 91.3295H362.335Z" fill="#9857FF"/>
      <path d="M219.548 56.1979V33.8327C219.548 30.3587 218.746 27.6863 217.163 25.9185C215.601 24.1507 213.237 23.2667 210.195 23.2667C206.762 23.2667 203.946 24.3768 202.013 26.453C200.081 28.5497 199.053 31.6331 199.053 35.395V56.2185H191.447V33.8327C191.447 26.823 188.282 23.2667 182.032 23.2667C178.6 23.2667 175.783 24.3768 173.851 26.453C171.919 28.5497 170.891 31.6331 170.891 35.395V56.2185H163.285V16.4215H170.685V20.7589C173.687 17.7988 178.003 16.1748 182.999 16.1748C188.96 16.1748 193.667 18.4977 196.36 22.7528C199.608 18.5593 204.85 16.1748 210.955 16.1748C215.93 16.1748 219.877 17.6137 222.693 20.43C225.632 23.3901 227.174 27.8713 227.154 33.3599V56.1979H219.548V56.1979Z" fill="white"/>
      <path d="M255.397 56.5679C243.145 56.5679 234.594 48.2426 234.594 36.32C234.594 24.644 242.775 16.1748 254.04 16.1748C265.387 16.1748 273.342 24.459 273.383 36.32C273.383 36.4433 273.363 36.5872 273.363 36.7106C273.342 36.8339 273.342 36.9367 273.342 37.0395V37.1011L273.322 37.1628C273.281 37.3684 273.281 37.5945 273.281 37.8617V39.1568H242.343C243.412 45.4881 248.49 49.5377 255.5 49.5377C259.796 49.5377 263.393 47.996 265.901 45.0975L266.888 44.008L271.41 49.1677L270.732 50.0516C267.196 54.2451 261.749 56.5679 255.397 56.5679ZM265.798 32.846C265.284 30.0503 263.989 27.6658 262.036 25.9185C259.919 24.0273 257.144 23.0406 254.061 23.0406C250.998 23.0406 248.264 24.0273 246.146 25.9185C244.194 27.6658 242.919 30.0503 242.405 32.846H265.798Z" fill="white"/>
      <path d="M337.274 56.198V33.8327C337.274 30.3587 336.473 27.6864 334.89 25.9185C333.328 24.1507 330.964 23.2668 327.921 23.2668C324.488 23.2668 321.672 24.3768 319.74 26.453C317.808 28.5497 316.78 31.6332 316.78 35.395V56.2185H309.174V33.8327C309.174 26.823 306.008 23.2668 299.759 23.2668C296.326 23.2668 293.51 24.3768 291.578 26.453C289.645 28.5497 288.618 31.6332 288.618 35.395V56.2185H281.012V16.4215H288.412V20.7589C291.413 17.7782 295.73 16.1543 300.725 16.1543C306.687 16.1543 311.394 18.4772 314.087 22.7323C317.335 18.5388 322.577 16.1543 328.682 16.1543C333.656 16.1543 337.603 17.5932 340.419 20.4095C343.359 23.3696 344.901 27.8508 344.88 33.3394V56.1774H337.274V56.198Z" fill="white"/>
      <path d="M354.297 69.9296V16.4215H361.697V21.3139C364.986 17.9427 369.467 16.1543 374.75 16.1543C386.241 16.1543 394.567 24.6235 394.567 36.2995C394.567 48.0371 386.241 56.5474 374.75 56.5474C369.57 56.5474 365.171 54.8413 361.923 51.5728V69.909H354.297V69.9296ZM374.422 23.1023C367.021 23.1023 361.841 28.5292 361.841 36.32C361.841 44.1109 367.021 49.5377 374.422 49.5377C381.822 49.5377 387.002 44.1109 387.002 36.32C387.002 28.6525 381.719 23.1023 374.422 23.1023Z" fill="white"/>
      <path d="M420.321 56.5679C408.604 56.5679 400.094 48.0576 400.094 36.32C400.094 24.644 408.604 16.1748 420.321 16.1748C425.995 16.1748 431.134 18.2099 434.813 21.91C438.472 25.5896 440.466 30.7081 440.446 36.3405C440.446 48.0576 431.977 56.5679 420.321 56.5679ZM420.321 23.1023C412.921 23.1023 407.741 28.5291 407.741 36.32C407.741 44.1108 412.921 49.5377 420.321 49.5377C427.66 49.5377 432.799 44.1108 432.799 36.32C432.778 28.5291 427.66 23.1023 420.321 23.1023Z" fill="white"/>
      <path d="M464.598 56.5679C452.881 56.5679 444.371 48.0576 444.371 36.32C444.371 24.644 452.881 16.1748 464.598 16.1748C476.254 16.1748 484.723 24.644 484.723 36.32C484.723 48.0576 476.254 56.5679 464.598 56.5679ZM464.598 23.1023C457.198 23.1023 452.018 28.5291 452.018 36.32C452.018 44.1108 457.198 49.5377 464.598 49.5377C471.937 49.5377 477.076 44.1108 477.076 36.32C477.076 28.5291 471.958 23.1023 464.598 23.1023Z" fill="white"/>
      <path d="M499.996 1.14844H492.391V56.1982H499.996V1.14844Z" fill="white"/>
      <path d="M124.706 110.25C124.706 118.849 117.772 125.791 109.183 125.791H15.5236C6.93387 125.791 0 118.849 0 110.25V16.4837C0 7.88416 6.98561 0.942383 15.5236 0.942383H109.183C117.772 0.942383 124.706 7.88416 124.706 16.4837V110.25Z" fill="#2E3349"/>
      <path d="M0 63.5225V110.25C0 118.849 6.98561 125.791 15.5753 125.791H109.183C117.772 125.791 124.758 118.849 124.758 110.25V63.5225H0Z" [attr.fill]="'url(#paint0_linear' + randomId + ')'"/>
      <path opacity="0.3" d="M109.909 109.11C109.909 111.026 108.615 112.581 107.011 112.581H90.8665C89.2624 112.581 87.9688 111.026 87.9688 109.11V17.6232C87.9688 15.7065 89.2624 14.1523 90.8665 14.1523H107.011C108.615 14.1523 109.909 15.7065 109.909 17.6232V109.11Z" fill="white"/>
      <defs>
        <linearGradient [id]="'paint0_linear' + randomId" x1="62.3768" y1="36.3949" x2="62.3768" y2="156.837" gradientUnits="userSpaceOnUse">
          <stop stop-color="#AE61FF"/>
          <stop offset="1" stop-color="#13EFD8"/>
        </linearGradient>
      </defs>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'warning'">
    <svg [class]="class" [style]="style" [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">  
      <path d="M135.3 34.474c-15.62 27.306-54.206 95.63-85.21 150.534L9.075 257.583C5.382 264.08 6.76 269.217 7.908 271.7c2.326 5.028 7.29 7.537 11.155 8.215l.78.133 264.698.006-.554-.02c4.152.255 9.664-1.24 12.677-6.194 1.926-3.18 3.31-8.589-1.073-16.278L213.637 114.37l-45.351-79.205c-5.681-9.932-12.272-12.022-16.8-12.022-4.42 0-10.818 1.964-16.181 11.331h-.006zm-69.072 159.94c30.997-54.885 69.563-123.184 85.16-150.446l.186-.297c.2.303.393.582.618.981l45.363 79.22s72.377 126.47 78.569 137.283l-247.618-.007 37.719-66.734" style="fill:#ffc107;fill-opacity:1"/>
      <path d="M152.597 247.445c8.02 0 14.518-6.728 14.518-15.025 0-8.29-6.499-15.018-14.518-15.018-8.031 0-14.529 6.728-14.529 15.018 0 8.297 6.498 15.025 14.53 15.025m-.001-147.18c11.586 0 22.23 10.958 20.977 21.7l-9.922 75.564c-.966 6.601-4.95 11.433-11.055 11.433s-10.102-4.832-11.056-11.433l-9.927-75.564c-1.26-10.742 9.39-21.7 20.983-21.7" style="fill:#ffc107;fill-opacity:1"/>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'mempoolSpace'">
    <svg [class]="class" [style]="style" [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M 219.548 86.198 L 219.548 63.833 C 219.548 60.359 218.746 57.686 217.163 55.919 C 215.601 54.151 213.237 53.267 210.195 53.267 C 206.762 53.267 203.946 54.377 202.013 56.453 C 200.081 58.55 199.053 61.633 199.053 65.395 L 199.053 86.219 L 191.447 86.219 L 191.447 63.833 C 191.447 56.823 188.282 53.267 182.032 53.267 C 178.6 53.267 175.783 54.377 173.851 56.453 C 171.919 58.55 170.891 61.633 170.891 65.395 L 170.891 86.219 L 163.285 86.219 L 163.285 46.422 L 170.685 46.422 L 170.685 50.759 C 173.687 47.799 178.003 46.175 182.999 46.175 C 188.96 46.175 193.667 48.498 196.36 52.753 C 199.608 48.559 204.85 46.175 210.955 46.175 C 215.93 46.175 219.877 47.614 222.693 50.43 C 225.632 53.39 227.174 57.871 227.154 63.36 L 227.154 86.198 L 219.548 86.198 Z" fill="white"/>
      <path d="M 255.397 86.568 C 243.145 86.568 234.594 78.243 234.594 66.32 C 234.594 54.644 242.775 46.175 254.04 46.175 C 265.387 46.175 273.342 54.459 273.383 66.32 C 273.383 66.443 273.363 66.587 273.363 66.711 C 273.342 66.834 273.342 66.937 273.342 67.04 L 273.342 67.101 L 273.322 67.163 C 273.281 67.368 273.281 67.595 273.281 67.862 L 273.281 69.157 L 242.343 69.157 C 243.412 75.488 248.49 79.538 255.5 79.538 C 259.796 79.538 263.393 77.996 265.901 75.098 L 266.888 74.008 L 271.41 79.168 L 270.732 80.052 C 267.196 84.245 261.749 86.568 255.397 86.568 Z M 265.798 62.846 C 265.284 60.05 263.989 57.666 262.036 55.919 C 259.919 54.027 257.144 53.041 254.061 53.041 C 250.998 53.041 248.264 54.027 246.146 55.919 C 244.194 57.666 242.919 60.05 242.405 62.846 L 265.798 62.846 Z" fill="white"/>
      <path d="M 337.274 86.198 L 337.274 63.833 C 337.274 60.359 336.473 57.686 334.89 55.919 C 333.328 54.151 330.964 53.267 327.921 53.267 C 324.488 53.267 321.672 54.377 319.74 56.453 C 317.808 58.55 316.78 61.633 316.78 65.395 L 316.78 86.219 L 309.174 86.219 L 309.174 63.833 C 309.174 56.823 306.008 53.267 299.759 53.267 C 296.326 53.267 293.51 54.377 291.578 56.453 C 289.645 58.55 288.618 61.633 288.618 65.395 L 288.618 86.219 L 281.012 86.219 L 281.012 46.422 L 288.412 46.422 L 288.412 50.759 C 291.413 47.778 295.73 46.154 300.725 46.154 C 306.687 46.154 311.394 48.477 314.087 52.732 C 317.335 48.539 322.577 46.154 328.682 46.154 C 333.656 46.154 337.603 47.593 340.419 50.41 C 343.359 53.37 344.901 57.851 344.88 63.339 L 344.88 86.177 L 337.274 86.177 L 337.274 86.198 Z" fill="white"/>
      <path d="M 354.297 99.93 L 354.297 46.422 L 361.697 46.422 L 361.697 51.314 C 364.986 47.943 369.467 46.154 374.75 46.154 C 386.241 46.154 394.567 54.624 394.567 66.3 C 394.567 78.037 386.241 86.547 374.75 86.547 C 369.57 86.547 365.171 84.841 361.923 81.573 L 361.923 99.909 L 354.297 99.909 L 354.297 99.93 Z M 374.422 53.102 C 367.021 53.102 361.841 58.529 361.841 66.32 C 361.841 74.111 367.021 79.538 374.422 79.538 C 381.822 79.538 387.002 74.111 387.002 66.32 C 387.002 58.653 381.719 53.102 374.422 53.102 Z" fill="white"/>
      <path d="M 420.321 86.568 C 408.604 86.568 400.094 78.058 400.094 66.32 C 400.094 54.644 408.604 46.175 420.321 46.175 C 425.995 46.175 431.134 48.21 434.813 51.91 C 438.472 55.59 440.466 60.708 440.446 66.341 C 440.446 78.058 431.977 86.568 420.321 86.568 Z M 420.321 53.102 C 412.921 53.102 407.741 58.529 407.741 66.32 C 407.741 74.111 412.921 79.538 420.321 79.538 C 427.66 79.538 432.799 74.111 432.799 66.32 C 432.778 58.529 427.66 53.102 420.321 53.102 Z" fill="white"/>
      <path d="M 464.598 86.568 C 452.881 86.568 444.371 78.058 444.371 66.32 C 444.371 54.644 452.881 46.175 464.598 46.175 C 476.254 46.175 484.723 54.644 484.723 66.32 C 484.723 78.058 476.254 86.568 464.598 86.568 Z M 464.598 53.102 C 457.198 53.102 452.018 58.529 452.018 66.32 C 452.018 74.111 457.198 79.538 464.598 79.538 C 471.937 79.538 477.076 74.111 477.076 66.32 C 477.076 58.529 471.958 53.102 464.598 53.102 Z" fill="white"/>
      <path d="M 499.996 31.148 L 492.391 31.148 L 492.391 86.198 L 499.996 86.198 L 499.996 31.148 Z" fill="white"/>
      <path d="M124.706 110.25C124.706 118.849 117.772 125.791 109.183 125.791H15.5236C6.93387 125.791 0 118.849 0 110.25V16.4837C0 7.88416 6.98561 0.942383 15.5236 0.942383H109.183C117.772 0.942383 124.706 7.88416 124.706 16.4837V110.25Z" fill="#2E3349"/>
      <path d="M0 63.5225V110.25C0 118.849 6.98561 125.791 15.5753 125.791H109.183C117.772 125.791 124.758 118.849 124.758 110.25V63.5225H0Z" [attr.fill]="'url(#paint0_linear' + randomId + ')'"/>
      <path opacity="0.3" d="M109.909 109.11C109.909 111.026 108.615 112.581 107.011 112.581H90.8665C89.2624 112.581 87.9688 111.026 87.9688 109.11V17.6232C87.9688 15.7065 89.2624 14.1523 90.8665 14.1523H107.011C108.615 14.1523 109.909 15.7065 109.909 17.6232V109.11Z" fill="white"/>
      <defs>
        <linearGradient [id]="'paint0_linear' + randomId" x1="62.3768" y1="36.3949" x2="62.3768" y2="156.837" gradientUnits="userSpaceOnUse">
          <stop stop-color="#AE61FF"/>
          <stop offset="1" stop-color="#13EFD8"/>
        </linearGradient>
      </defs>
    </svg>
  </ng-container>
  <ng-container *ngSwitchCase="'bitcoin'">
    <ng-component *ngTemplateOutlet="bitcoinLogo; context: {$implicit: '#f7931a', width, height, viewBox}"></ng-component>
  </ng-container>
  <ng-container *ngSwitchCase="'signet'">
    <ng-component *ngTemplateOutlet="bitcoinLogo; context: {$implicit: '#b028aa', width, height, viewBox}"></ng-component>
  </ng-container>
  <ng-container *ngSwitchCase="'testnet'">
    <ng-component *ngTemplateOutlet="bitcoinLogo; context: {$implicit: '#5fd15c', width, height, viewBox}"></ng-component>
  </ng-container>
  <ng-container *ngSwitchCase="'bisq'">
    <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox"><path id="Combined-Shape" fill="#25b135" d="M62.6 18.2c2.6 5.7 3.2 6.8 5.2 12.6 1.1 3.6-1.2 9-10.6 4.4-6.8-3.2-11.7-5.5-7.6-10.9 2-2.6 4.2-5 6.6-7.3 2.5-2.2 4.1-3.6 6.4 1.2zm-2.3 36.4c5-4.9 11.9.6 7.1 7.1C57.6 75 40.7 80.3 25.5 74.9S0 55 0 38.3c0-10 3.3-18.4 9.6-25.9C15.7 5.6 24.8.1 33.8 0 46.4 0 52 11 47.6 17.2c-5 6.8-10.7 6.3-15 3.3 0 0-5.9-4.2-7.6-5.6-2.7-2.1-5.6-2.1-6.7 1.3-1.9 6-2.8 9.8-4.3 15.8-.7 3-1 6.1-1.1 9.2 0 5.7 2.5 11.1 6.8 14.6 3 2.8 6.1 5.3 9.5 7.5 2.7 1.9 5.9 2.9 9.1 2.9h.3c3.2-.1 6.4-1.1 9.1-2.9 4.4-2.6 8.6-5.5 12.6-8.7zm-35.4-7c-1.8-.7-5.8-4.2-4.6-6.8.5-1.4 3.9-1.8 5-1.8 6.2-.1 6.5 11.1-.4 8.6zm11.1 12c-.8-.7-2.3-2.2-3.6-3.4-.4-.4-.4-1-.2-1.4.3-.5.8-.8 1.4-.7 2.4-.1 4.8-.1 7.1 0 .6 0 1 .4 1.2.8.2.5.1 1-.3 1.3-1.3 1.2-2.8 2.7-3.5 3.4-.3.3-.7.4-1.1.4-.4 0-.7-.1-1-.4zm14.1-12c-6.9 2.5-6.7-8.8-.4-8.6 1.1 0 4.4.4 5 1.8 1.3 2.7-2.8 6.2-4.6 6.8z"/></svg>
  </ng-container>
  <ng-container *ngSwitchCase="'liquid'">
    <ng-component *ngTemplateOutlet="liquidLogo; context: {$implicit: '', width, height, viewBox, color1: '#2cccbf', color2: '#9ef2ed'}"></ng-component>
  </ng-container>
  <ng-container *ngSwitchCase="'liquidtestnet'">
    <ng-component *ngTemplateOutlet="liquidLogo; context: {$implicit: '', width, height, viewBox, color1: '#858585', color2: '#c6c6c6'}"></ng-component>
  </ng-container>
  <ng-container *ngSwitchCase="'clippy'">
    <svg [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox" xmlns="http://www.w3.org/2000/svg">
      <path fill="#FFFFFF" d="M128 768h256v64H128v-64z m320-384H128v64h320v-64z m128 192V448L384 640l192 192V704h320V576H576z m-288-64H128v64h160v-64zM128 704h160v-64H128v64z m576 64h64v128c-1 18-7 33-19 45s-27 18-45 19H64c-35 0-64-29-64-64V192c0-35 29-64 64-64h192C256 57 313 0 384 0s128 57 128 128h192c35 0 64 29 64 64v320h-64V320H64v576h640V768zM128 256h512c0-35-29-64-64-64h-64c-35 0-64-29-64-64s-29-64-64-64-64 29-64 64-29 64-64 64h-64c-35 0-64 29-64 64z" />
    </svg>
  </ng-container>
</ng-container>

<ng-template #bitcoinLogo let-color let-width="width" let-height="height" let-viewBox="viewBox">
  <svg xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="viewBox" [attr.width]="width" [attr.height]="height" version="1.1" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/">
    <g transform="translate(0.00630876,-0.00301984)">
    <path [attr.fill]="color" d="m63.033,39.744c-4.274,17.143-21.637,27.576-38.782,23.301-17.138-4.274-27.571-21.638-23.295-38.78,4.272-17.145,21.635-27.579,38.775-23.305,17.144,4.274,27.576,21.64,23.302,38.784z"/>
    <path fill="#FFF" d="m46.103,27.444c0.637-4.258-2.605-6.547-7.038-8.074l1.438-5.768-3.511-0.875-1.4,5.616c-0.923-0.23-1.871-0.447-2.813-0.662l1.41-5.653-3.509-0.875-1.439,5.766c-0.764-0.174-1.514-0.346-2.242-0.527l0.004-0.018-4.842-1.209-0.934,3.75s2.605,0.597,2.55,0.634c1.422,0.355,1.679,1.296,1.636,2.042l-1.638,6.571c0.098,0.025,0.225,0.061,0.365,0.117-0.117-0.029-0.242-0.061-0.371-0.092l-2.296,9.205c-0.174,0.432-0.615,1.08-1.609,0.834,0.035,0.051-2.552-0.637-2.552-0.637l-1.743,4.019,4.569,1.139c0.85,0.213,1.683,0.436,2.503,0.646l-1.453,5.834,3.507,0.875,1.439-5.772c0.958,0.26,1.888,0.5,2.798,0.726l-1.434,5.745,3.511,0.875,1.453-5.823c5.987,1.133,10.489,0.676,12.384-4.739,1.527-4.36-0.076-6.875-3.226-8.515,2.294-0.529,4.022-2.038,4.483-5.155zm-8.022,11.249c-1.085,4.36-8.426,2.003-10.806,1.412l1.928-7.729c2.38,0.594,10.012,1.77,8.878,6.317zm1.086-11.312c-0.99,3.966-7.1,1.951-9.082,1.457l1.748-7.01c1.982,0.494,8.365,1.416,7.334,5.553z"/>
    </g>
  </svg>
</ng-template>

<ng-template #liquidLogo let-width="width" let-height="height" let-viewBox="viewBox" let-color1="color1" let-color2="color2">
  <svg [attr.width]="width" [attr.height]="height" [attr.viewBox]="viewBox" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="path-1" points="0 0.649692716 52.8225298 0.649692716 52.8225298 106.004141 0 106.004141"/>
    </defs>
    <g id="Unified-Headers" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Assets" transform="translate(-190.000000, -200.000000)">
        <g id="brand/products/logos/liquid" transform="translate(190.000000, 200.000000)">
          <g id="Group-38" transform="translate(0.000000, -0.000000)">
            <path d="M23.1161265,84.4720497 C20.9217995,84.4720497 18.8355937,83.190767 17.9227679,81.0564707 C14.8209325,73.8058557 13.2505176,66.1022975 13.2505176,58.160812 C13.2505176,28.6260995 35.4507963,3.63844273 64.8916448,0.0413340043 C67.9881627,-0.330537599 70.7975393,1.84781928 71.178622,4.92325031 C71.5597046,7.99691891 69.3600602,10.7956493 66.2688597,11.1728081 C42.4733515,14.0825713 24.5305634,34.2817203 24.5305634,58.160812 C24.5305634,64.5866123 25.8014296,70.8132586 28.3041676,76.6662709 C29.523632,79.5161116 28.1871841,82.8118457 25.3210883,84.0226314 C24.59969,84.3275309 23.8517046,84.4720497 23.1161265,84.4720497" id="Fill-1" [attr.fill]="color1"/>
            <path d="M35.3026907,53.8302277 C34.9875568,53.8302277 34.6653808,53.791147 34.3467259,53.7076562 C32.2974754,53.1765128 31.0651083,51.0697032 31.5915051,49.0037509 C36.4646368,29.9003529 53.5346821,16.563147 73.1064326,16.563147 C83.0921274,16.563147 92.8137435,20.1017344 100.480828,26.5305234 C102.107552,27.8912453 102.331139,30.3284651 100.979056,31.9680817 C99.6304943,33.6094746 97.2150547,33.8350773 95.5883301,32.4725791 C89.2926946,27.1948968 81.3087161,24.2869311 73.1064326,24.2869311 C57.0293232,24.2869311 43.0085063,35.2419856 39.0068342,50.9275913 C38.563182,52.6737918 37.0068784,53.8302277 35.3026907,53.8302277" id="Fill-3" [attr.fill]="color2"/>
            <path d="M70.2960951,99.2782606 C65.7440202,98.9692101 61.3042321,97.9493436 57.1014942,96.2404763 C55.1124121,95.4314912 54.1428237,93.1317921 54.9341789,91.1029667 C55.7290988,89.0759592 57.981966,88.0869978 59.9710481,88.8941649 C63.4198597,90.2976176 67.0665102,91.1356897 70.811189,91.3902019 C75.981733,91.741065 81.0382078,90.9738927 85.8415915,89.1068643 C87.8431499,88.3324202 90.0817584,89.3559226 90.8428141,91.3956557 C91.6038697,93.4353888 90.600417,95.7205443 88.6006409,96.4986243 C82.7456815,98.7710542 76.5895081,99.7054774 70.2960951,99.2782606" id="Fill-5" [attr.fill]="color2"/>
            <g id="Group-9" transform="translate(0.000000, 18.219462)">
              <mask id="mask-2" fill="white">
                <polygon points="0 0.649692716 52.8225298 0.649692716 52.8225298 106.004141 0 106.004141" transform="matrix(1, 0, 0, 1, 0, 0)"/>
              </mask>
              <g id="Clip-8"/>
              <path d="M48.9808033,106.004671 C48.6097888,106.004671 48.2317074,105.949902 47.8589262,105.835064 C19.6812588,97.2416618 -0.000176673568,69.6717516 -0.000176673568,39.142559 C-0.000176673568,26.0704817 3.71526846,13.3711856 10.7451097,2.41742443 C11.8881877,0.631254658 14.2644472,0.111834369 16.0523837,1.26021256 C17.8385535,2.40505728 18.3579738,4.78308351 17.2113623,6.56925328 C10.9783188,16.2809993 7.6833568,27.545706 7.6833568,39.142559 C7.6833568,66.3220207 25.1263382,90.870813 50.1009137,98.4854438 C52.130893,99.105568 53.273971,101.252152 52.6538468,103.282131 C52.1485604,104.937562 50.627401,106.004671 48.9808033,106.004671" id="Fill-7" mask="url(#mask-2)" [attr.fill]="color2"/>
            </g>
            <path d="M72.4972871,114.285714 C55.7249457,114.285714 40.4087071,107.985296 30.1336329,96.7687076 C28.493546,94.9775315 28.6267155,92.2090341 30.4297597,90.5795855 C32.2328039,88.953615 35.0241056,89.0840404 36.6659447,90.8752165 C45.7407417,100.778855 59.7007546,106.112386 74.9066028,105.474171 C77.350963,105.359397 79.3975672,107.253174 79.4991965,109.670393 C79.6008258,112.085872 77.7084179,114.129204 75.2710665,114.230066 C74.3441371,114.266585 73.4154554,114.285714 72.4972871,114.285714" id="Fill-10" [attr.fill]="color1"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
