<div class="fee-estimation-wrapper" *ngIf="$rewardStats | async as rewardStats; else loadingReward">
  <div class="fee-estimation-container">
    <div class="item">
      <h5 class="card-title" i18n="mining.rewards" i18n-ngbTooltip="mining.rewards"
      ngbTooltip="Miners Reward" placement="bottom" #minersreward [disableTooltip]="!isEllipsisActive(minersreward)">Miners Reward</h5>
      <div class="card-text" i18n-ngbTooltip="mining.rewards-desc" ngbTooltip="Amount being paid to miners in the past 144 blocks" placement="bottom">
        <div class="fee-text">
          <app-amount [satoshis]="rewardStats.totalReward" digitsInfo="1.2-2" [noFiat]="true"></app-amount>
        </div>
        <span class="fiat">
          <app-fiat [value]="rewardStats.totalReward" digitsInfo="1.0-0" ></app-fiat>
        </span>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="mining.fees-per-block" i18n-ngbTooltip="mining.fees-per-block"
      ngbTooltip="Avg Block Fees" placement="bottom" #rewardsperblock [disableTooltip]="!isEllipsisActive(rewardsperblock)">Avg Block Fees</h5>
      <div class="card-text" i18n-ngbTooltip="mining.fees-per-block-desc" ngbTooltip="Average fees per block in the past 144 blocks" placement="bottom">
        <div class="fee-text">
          {{ (rewardStats.feePerBlock / 100000000) | amountShortener: 4 }}
          <span i18n="shared.btc-block|BTC/block">BTC/block</span>
        </div>
        <span class="fiat">
          <app-fiat [value]="rewardStats.feePerBlock"></app-fiat>
        </span>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="mining.average-fee" i18n-ngbTooltip="mining.average-fee"
      ngbTooltip="Avg Tx Fee" placement="bottom" #averagefee [disableTooltip]="!isEllipsisActive(averagefee)">Avg Tx Fee</h5>
      <div class="card-text" i18n-ngbTooltip="mining.average-fee" ngbTooltip="Fee paid on average for each transaction in the past 144 blocks" placement="bottom">
        <div class="fee-text">{{ rewardStats.feePerTx | amountShortener: 2 }}
          <span i18n="shared.sat-vbyte|sat/vB">sats/tx</span>
        </div>
        <span class="fiat">
          <app-fiat [value]="rewardStats.feePerTx"></app-fiat>
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingReward>
  <div class="fee-estimation-container loading-container">
    <div class="item">
      <h5 class="card-title" i18n="mining.rewards">Miners Reward</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="mining.fees-per-block">Avg Block Fees</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <h5 class="card-title" i18n="mining.average-fee">Avg Tx Fee</h5>
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
  </div>
</ng-template>
