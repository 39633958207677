<ng-container *ngIf="!noFiat && (viewFiat$ | async) && (conversions$ | async) as conversions; else viewFiatVin">
  <span class="fiat" *ngIf="blockConversion; else noblockconversion">
    {{ addPlus && satoshis >= 0 ? '+' : '' }}{{
      (
        (blockConversion.price[currency] > -1 ? blockConversion.price[currency] : null) ??
        (blockConversion.price['USD']    > -1 ? blockConversion.price['USD'] * blockConversion.exchangeRates['USD' + currency] : null) ?? 0
      ) * satoshis / 100000000 | fiatCurrency : digitsInfo : currency
    }}
  </span>
  <ng-template #noblockconversion>
    <span class="fiat">{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ (conversions[currency] > -1 ? conversions[currency] : 0) * satoshis / 100000000 | fiatCurrency : digitsInfo : currency }}
    </span>
  </ng-template>
</ng-container>

  <ng-template #viewFiatVin>
  <ng-template [ngIf]="(network === 'liquid' || network === 'liquidtestnet') && (satoshis === undefined || satoshis === null)" [ngIfElse]="default">
    <span i18n="shared.confidential">Confidential</span>
  </ng-template>
  <ng-template #default>
    &lrm;{{ addPlus && satoshis >= 0 ? '+' : '' }}{{ satoshis / 100000000 | number : digitsInfo }}
    <span class="symbol"><ng-template [ngIf]="network === 'liquid'">L-</ng-template>
    <ng-template [ngIf]="network === 'liquidtestnet'">tL-</ng-template>
    <ng-template [ngIf]="network === 'testnet'">t</ng-template>
    <ng-template [ngIf]="network === 'signet'">s</ng-template>BTC</span>
  </ng-template>
</ng-template>
