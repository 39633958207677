<div class="container-xl">

  <div class="title-block">
    <div *ngIf="rbfTransaction" class="alert alert-mempool" role="alert">
      <span i18n="transaction.rbf.replacement|RBF replacement">This transaction has been replaced by:</span>
      <app-truncate [text]="rbfTransaction.txid" [lastChars]="12" [link]="['/tx/' | relativeUrl, rbfTransaction.txid]"></app-truncate>
    </div>

    <div *ngIf="rbfReplaces?.length" class="alert alert-mempool" role="alert">
      <span i18n="transaction.rbf.replaced|RBF replaced">This transaction replaced:</span>
      <div class="tx-list">
        <app-truncate [text]="replaced" [lastChars]="12" *ngFor="let replaced of rbfReplaces" [link]="['/tx/' | relativeUrl, replaced]"></app-truncate>
      </div>
    </div>

    <ng-container *ngIf="!rbfTransaction || rbfTransaction?.size || tx">
      <h1 i18n="shared.transaction">Transaction</h1>

      <span class="tx-link">
        <span class="txid">
          <app-truncate [text]="txId" [lastChars]="12" [link]="['/tx/' | relativeUrl, txId]">
            <app-clipboard [text]="txId"></app-clipboard>
          </app-truncate>
        </span>
      </span>

      <div class="container-buttons">
        <ng-template [ngIf]="tx?.status?.confirmed">
          <button *ngIf="latestBlock" type="button" class="btn btn-sm btn-success">
            <ng-container *ngTemplateOutlet="latestBlock.height - tx.status.block_height + 1 == 1 ? confirmationSingular : confirmationPlural; context: {$implicit: latestBlock.height - tx.status.block_height + 1}"></ng-container>
            <ng-template #confirmationSingular let-i i18n="shared.confirmation-count.singular|Transaction singular confirmation count">{{ i }} confirmation</ng-template>
            <ng-template #confirmationPlural let-i i18n="shared.confirmation-count.plural|Transaction plural confirmation count">{{ i }} confirmations</ng-template>
          </button>
        </ng-template>
        <ng-template [ngIf]="tx && !tx?.status?.confirmed && replaced">
          <button type="button" class="btn btn-sm btn-danger" i18n="transaction.unconfirmed|Transaction unconfirmed state">Replaced</button>
        </ng-template>
        <ng-template [ngIf]="tx && !tx?.status?.confirmed && !replaced">
          <button type="button" class="btn btn-sm btn-danger" i18n="transaction.unconfirmed|Transaction unconfirmed state">Unconfirmed</button>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <div class="clearfix"></div>

  <ng-template [ngIf]="!isLoadingTx && !error">

    <ng-template [ngIf]="tx.status.confirmed" [ngIfElse]="unconfirmedTemplate">

      <div class="box">
        <div class="row">
          <div class="col-sm">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr>
                  <td i18n="block.timestamp">Timestamp</td>
                  <td>
                    &lrm;{{ tx.status.block_time * 1000 | date:'yyyy-MM-dd HH:mm' }}
                    <div class="lg-inline">
                      <i class="symbol">(<app-time kind="since" [time]="tx.status.block_time" [fastRender]="true"></app-time>)</i>
                    </div>
                  </td>
                </tr>
                <ng-template [ngIf]="transactionTime > 0">
                  <tr>
                    <td i18n="transaction.confirmed|Transaction Confirmed state">Confirmed</td>
                    <td><app-time kind="span" [time]="tx.status.block_time - transactionTime" [fastRender]="true" [relative]="true"></app-time></td>
                  </tr>
                </ng-template>
                <tr *ngIf="network !== 'liquid' && network !== 'liquidtestnet' && featuresEnabled">
                  <td class="td-width" i18n="transaction.features|Transaction features">Features</td>
                  <td>
                    <app-tx-features [tx]="tx"></app-tx-features>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm">
            <ng-container *ngTemplateOutlet="feeTable"></ng-container>
          </div>

        </div>
      </div>

    </ng-template>

    <ng-template #unconfirmedTemplate>

      <div class="box">
        <div class="row">
          <div class="col-sm">
            <table class="table table-borderless table-striped">
              <tbody>
                <ng-template [ngIf]="transactionTime !== 0 && !replaced">
                  <tr *ngIf="transactionTime === -1; else firstSeenTmpl">
                    <td><span class="skeleton-loader"></span></td>
                    <td><span class="skeleton-loader"></span></td>
                  </tr>
                  <ng-template #firstSeenTmpl>
                    <tr>
                      <td i18n="transaction.first-seen|Transaction first seen">First seen</td>
                      <td><i><app-time kind="since" [time]="transactionTime" [fastRender]="true"></app-time></i></td>
                    </tr>
                  </ng-template>
                </ng-template>
                <tr *ngIf="!replaced">
                  <td class="td-width" i18n="transaction.eta|Transaction ETA">ETA</td>
                  <td>
                    <ng-template [ngIf]="txInBlockIndex === undefined" [ngIfElse]="estimationTmpl">
                      <span class="skeleton-loader"></span>
                    </ng-template>
                    <ng-template #estimationTmpl>
                      <ng-template [ngIf]="txInBlockIndex >= 7" [ngIfElse]="belowBlockLimit">
                        <span i18n="transaction.eta.in-several-hours|Transaction ETA in several hours or more">In several hours (or more)</span>
                      </ng-template>
                      <ng-template #belowBlockLimit>
                        <ng-template [ngIf]="network === 'liquid' || network === 'liquidtestnet'" [ngIfElse]="timeEstimateDefault">
                          <app-time kind="until" [time]="(60 * 1000 * txInBlockIndex) + now" [fastRender]="false" [fixedRender]="true"></app-time>
                        </ng-template>
                        <ng-template #timeEstimateDefault>
                          <app-time kind="until" *ngIf="(timeAvg$ | async) as timeAvg;" [time]="(timeAvg * txInBlockIndex) + now + timeAvg" [fastRender]="false" [fixedRender]="true" [forceFloorOnTimeIntervals]="['hour']"></app-time>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  </td>
                </tr>
                <tr *ngIf="network !== 'liquid' && network !== 'liquidtestnet'">
                  <td class="td-width" i18n="transaction.features|Transaction Features">Features</td>
                  <td>
                    <app-tx-features [tx]="tx"></app-tx-features>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm">
            <ng-container *ngTemplateOutlet="feeTable"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="showCpfpDetails">
      <br>

      <h2 class="text-left">CPFP <fa-icon [icon]="['fas', 'info-circle']" [fixedWidth]="true" size="xs"></fa-icon></h2>
      <div class="box cpfp-details">
        <table class="table table-fixed table-borderless table-striped">
          <thead>
            <tr>
              <th i18n="transactions-list.vout.scriptpubkey-type">Type</th>
              <th class="txids" i18n="dashboard.latest-transactions.txid">TXID</th>
              <th class="d-none d-lg-table-cell" i18n="transaction.vsize|Transaction Virtual Size">Virtual size</th>
              <th i18n="transaction.fee-rate|Transaction fee rate">Fee rate</th>
              <th class="d-none d-lg-table-cell"></th>
            </tr>
          </thead>
          <tbody>
            <ng-template [ngIf]="cpfpInfo?.descendants?.length">
              <tr *ngFor="let cpfpTx of cpfpInfo.descendants">
                <td><span class="badge badge-primary" i18n="transaction.descendant|Descendant">Descendant</span></td>
                <td>
                  <app-truncate [text]="cpfpTx.txid" [link]="['/tx' | relativeUrl, cpfpTx.txid]"></app-truncate>
                </td>
                <td class="d-none d-lg-table-cell" [innerHTML]="cpfpTx.weight / 4 | vbytes: 2"></td>
                <td>{{ cpfpTx.fee / (cpfpTx.weight / 4) | feeRounding }} <span class="symbol" i18n="shared.sat-vbyte|sat/vB">sat/vB</span></td>
                <td class="d-none d-lg-table-cell"><fa-icon  *ngIf="roundToOneDecimal(cpfpTx) > roundToOneDecimal(tx)"  class="arrow-green" [icon]="['fas', 'angle-double-up']" [fixedWidth]="true"></fa-icon></td>
              </tr>
            </ng-template>
            <ng-template [ngIf]="cpfpInfo?.bestDescendant">
              <tr>
                <td><span class="badge badge-success" i18n="transaction.descendant|Descendant">Descendant</span></td>
                <td class="txids">
                  <app-truncate [text]="cpfpInfo.bestDescendant.txid" [link]="['/tx' | relativeUrl, cpfpInfo.bestDescendant.txid]"></app-truncate>
                </td>
                <td class="d-none d-lg-table-cell" [innerHTML]="cpfpInfo.bestDescendant.weight / 4 | vbytes: 2"></td>
                <td>{{ cpfpInfo.bestDescendant.fee / (cpfpInfo.bestDescendant.weight / 4) | feeRounding }} <span class="symbol" i18n="shared.sat-vbyte|sat/vB">sat/vB</span></td>
                <td class="d-none d-lg-table-cell"><fa-icon class="arrow-green" [icon]="['fas', 'angle-double-up']" [fixedWidth]="true"></fa-icon></td>
              </tr>
            </ng-template>
            <ng-template [ngIf]="cpfpInfo?.ancestors?.length">
              <tr *ngFor="let cpfpTx of cpfpInfo.ancestors">
                <td><span class="badge badge-primary" i18n="transaction.ancestor|Transaction Ancestor">Ancestor</span></td>
                <td class="txids">
                  <app-truncate [text]="cpfpTx.txid" [link]="['/tx' | relativeUrl, cpfpTx.txid]"></app-truncate>
                </td>
                <td class="d-none d-lg-table-cell" [innerHTML]="cpfpTx.weight / 4 | vbytes: 2"></td>
                <td>{{ cpfpTx.fee / (cpfpTx.weight / 4) | feeRounding }} <span class="symbol" i18n="shared.sat-vbyte|sat/vB">sat/vB</span></td>
                <td class="d-none d-lg-table-cell"><fa-icon *ngIf="roundToOneDecimal(cpfpTx) < roundToOneDecimal(tx)" class="arrow-red" [icon]="['fas', 'angle-double-down']" [fixedWidth]="true"></fa-icon></td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </ng-template>

    <br>

    <ng-container *ngIf="flowEnabled; else flowPlaceholder">
      <div class="title float-left">
        <h2 id="flow" i18n="transaction.flow|Transaction flow">Flow</h2>
      </div>

      <button type="button" class="btn btn-outline-info flow-toggle btn-sm float-right" (click)="toggleGraph()" i18n="hide-diagram">Hide diagram</button>

      <div class="clearfix"></div>

      <div class="box">
        <div class="graph-container" #graphContainer>
          <tx-bowtie-graph
            [tx]="tx"
            [cached]="isCached"
            [width]="graphWidth"
            [height]="graphHeight"
            [lineLimit]="inOutLimit"
            [maxStrands]="graphExpanded ? maxInOut : 24"
            [network]="network"
            [tooltip]="true"
            [connectors]="true"
            [inputIndex]="inputIndex" [outputIndex]="outputIndex"
          >
          </tx-bowtie-graph>
        </div>
        <div class="toggle-wrapper" *ngIf="maxInOut > 24">
          <button class="btn btn-sm btn-primary graph-toggle" (click)="expandGraph();" *ngIf="!graphExpanded; else collapseBtn"><span i18n="show-more">Show more</span></button>
          <ng-template #collapseBtn>
            <button class="btn btn-sm btn-primary graph-toggle" (click)="collapseGraph();"><span i18n="show-less">Show less</span></button>
          </ng-template>
        </div>
      </div>

      <br>
    </ng-container>
    <ng-template #flowPlaceholder>
      <div class="box hidden">
        <div class="graph-container" #graphContainer>
        </div>
      </div>
    </ng-template>

    <div class="subtitle-block">
      <div class="title">
        <h2 i18n="transaction.inputs-and-outputs|Transaction inputs and outputs">Inputs & Outputs</h2>
      </div>

      <div class="title-buttons">
        <button *ngIf="!flowEnabled" type="button" class="btn btn-outline-info flow-toggle btn-sm" (click)="toggleGraph()" i18n="show-diagram">Show diagram</button>
        <button type="button" class="btn btn-outline-info btn-sm" (click)="txList.toggleDetails()" i18n="transaction.details|Transaction Details">Details</button>
      </div>
    </div>


    <app-transactions-list #txList [transactions]="[tx]" [cached]="isCached" [errorUnblinded]="errorUnblinded" [inputIndex]="inputIndex" [outputIndex]="outputIndex" [transactionPage]="true"></app-transactions-list>

    <div class="title text-left">
      <h2 i18n="transaction.details">Details</h2>
    </div>
    <div class="box">
      <div class="row">
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td i18n="block.size">Size</td>
                <td [innerHTML]="'&lrm;' + (tx.size | bytes: 2)"></td>
              </tr>
              <tr>
                <td i18n="transaction.vsize|Transaction Virtual Size">Virtual size</td>
                <td [innerHTML]="'&lrm;' + (tx.weight / 4 | vbytes: 2)"></td>
              </tr>
              <tr>
                <td i18n="block.weight">Weight</td>
                <td [innerHTML]="'&lrm;' + (tx.weight | wuBytes: 2)"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td i18n="transaction.version">Version</td>
                <td [innerHTML]="'&lrm;' + (tx.version | number)"></td>
              </tr>
              <tr>
                <td i18n="transaction.locktime">Locktime</td>
                <td [innerHTML]="'&lrm;' + (tx.locktime | number)"></td>
              </tr>
              <tr>
                <td i18n="transaction.hex">Transaction hex</td>
                <td><a target="_blank" href="{{ network === '' ? '' : '/' + network }}/api/tx/{{ txId }}/hex"><fa-icon [icon]="['fas', 'external-link-alt']" [fixedWidth]="true"></fa-icon></a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template [ngIf]="isLoadingTx && !error">

    <div class="box">
      <div class="row">
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td class="td-width"><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td class="td-width"><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <br>

    <ng-container *ngIf="flowEnabled">
      <div class="title">
        <h2 i18n="transaction.flow|Transaction flow">Flow</h2>
      </div>

      <div class="box">
        <div class="graph-container" #graphContainer style="visibility: hidden;"></div>
        <div class="row">
          <div class="col-sm">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr>
                  <td><span class="skeleton-loader"></span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm">
            <table class="table table-borderless table-striped">
              <tbody>
                <tr>
                  <td><span class="skeleton-loader"></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br>
    </ng-container>

    <div class="title">
      <h2 i18n="transaction.inputs-and-outputs|Transaction inputs and outputs">Inputs & Outputs</h2>
    </div>

    <div class="box">
      <div class="row">
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <br>

    <div class="title">
      <h2 i18n="transaction.details">Details</h2>
    </div>

    <div class="box">
      <div class="row">
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-sm">
          <table class="table table-borderless table-striped">
            <tbody>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
              <tr>
                <td><span class="skeleton-loader"></span></td>
                <td><span class="skeleton-loader"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template [ngIf]="error">

    <div class="text-center" *ngIf="waitingForTransaction; else errorTemplate">
      <h3 i18n="transaction.error.transaction-not-found">Transaction not found.</h3>
      <h5 i18n="transaction.error.waiting-for-it-to-appear">Waiting for it to appear in the mempool...</h5>
      <div class="spinner-border text-light mt-2"></div>
    </div>

    <ng-template #errorTemplate>
      <div class="text-center">
        <h3>{{ error.error }}</h3>
      </div>
    </ng-template>
  </ng-template>

  <br>

</div>

<br>

<ng-template #feeTable>
  <table class="table table-borderless table-striped">
    <tbody>
      <tr *ngIf="isMobile && (network === 'liquid' || network === 'liquidtestnet' || !featuresEnabled)"></tr>
      <tr>
        <td class="td-width" i18n="transaction.fee|Transaction fee">Fee</td>
        <td>{{ tx.fee | number }} <span class="symbol" i18n="shared.sat|sat">sat</span> <span class="fiat"><app-fiat [blockConversion]="blockConversion" [value]="tx.fee"></app-fiat></span></td>
      </tr>
      <tr>
        <td i18n="transaction.fee-rate|Transaction fee rate">Fee rate</td>
        <td>
          {{ tx.feePerVsize | feeRounding }} <span class="symbol" i18n="shared.sat-vbyte|sat/vB">sat/vB</span>
          <ng-template [ngIf]="tx.status.confirmed">
            &nbsp;
            <app-tx-fee-rating *ngIf="tx.fee && ((cpfpInfo && !cpfpInfo?.descendants?.length && !cpfpInfo?.bestDescendant && !cpfpInfo?.ancestors?.length) || !cpfpInfo)" [tx]="tx"></app-tx-fee-rating>
          </ng-template>
        </td>
      </tr>
      <tr *ngIf="cpfpInfo && (cpfpInfo?.bestDescendant || cpfpInfo?.descendants?.length || cpfpInfo?.ancestors?.length)">
        <td i18n="transaction.effective-fee-rate|Effective transaction fee rate">Effective fee rate</td>
        <td>
          <div class="effective-fee-container">
            {{ tx.effectiveFeePerVsize | feeRounding }} <span class="symbol" i18n="shared.sat-vbyte|sat/vB">sat/vB</span>
            <ng-template [ngIf]="tx.status.confirmed">
              <app-tx-fee-rating class="ml-2 mr-2" *ngIf="tx.fee || tx.effectiveFeePerVsize" [tx]="tx"></app-tx-fee-rating>
            </ng-template>
          </div>
          <button type="button" class="btn btn-outline-info btn-sm btn-small-height float-right" (click)="showCpfpDetails = !showCpfpDetails">CPFP <fa-icon [icon]="['fas', 'info-circle']" [fixedWidth]="true"></fa-icon></button>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
