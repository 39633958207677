<div class="container-xl">
  <div class="text-center">
    <br />
    <img [src]="officialMempoolSpace ? '/resources/mempool-space-logo-bigger.png' : '/resources/mempool-logo-bigger.png'" style="width: 250px;height:63px;">
    <br /><br />

    <h2>Terms of Service</h2>
    <h6>Updated: August 02, 2021</h6>

    <br><br>

    <div class="text-left">

        <p *ngIf="officialMempoolSpace">The <a href="https://mempool.space/">mempool.space</a> website, the <a href="https://liquid.network/">liquid.network</a> website, the <a href="https://bisq.markets/">bisq.markets</a> website, their associated API services, and related network and server infrastructure (collectively, the "Website") are operated by Mempool Space K.K. in Japan ("Mempool", "We", or "Us") and self-hosted from <a href="https://bgp.tools/as/142052#connectivity">AS142052</a>.</p>

        <p *ngIf="!officialMempoolSpace">This website and its API service (collectively, the "Website") are operated by a member of the Bitcoin community ("We" or "Us"). Mempool Space K.K. in Japan ("Mempool") has no affiliation with the operator of this Website, and does not sponsor or endorse the information provided herein.</p>

        <h5>By accessing this Website, you agree to the following Terms of Service:</h5>

        <br>

        <h4>NO WARRANTY</h4>

        <p>THIS WEBSITE IS PROVIDED ON AN "AS IS, AS AVAILABLE" BASIS, WITHOUT ANY WARRANTY OR REPRESENTATIONS; EXPRESS OR IMPLIED, WITHOUT EVEN THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, CONTINUOUS AVAILABILITY, OR UNINTERRUPTED SERVICE. WE MAKE NO GUARANTEE TO THE ACCURACY OF ANY DATA ON THIS WEBSITE, AND IT IS YOUR RESPONSIBILITY TO VERIFY ALL DATA OBTAINED FOR INACCURACIES AND OMISSIONS.</p>

        <br>

        <h4>NO LIABILITY</h4>

        <p>WE WILL NOT BE HELD LIABLE FOR ANY LOSS OR DAMAGES, WHETHER DIRECT, INDIRECT, OR ANY OTHER NATURE, THAT ARE DEEMED OR ALLEGED TO HAVE RESULTED FROM OR BEEN CAUSED BY THIS WEBSITE OR ITS CONTENTS, INCLUDING, BUT WITHOUT LIMITATION TO, ERRORS, INACCURACIES OR AMBIGUITY IN THIS WEBSITE OR ITS CONTENTS, FAILURES, MALFUNCTIONS, DELAYS OR INTERRUPTIONS IN OPERATION OR TRANSMISSION, ANY PERSONS USE OR MISUSE OF THIS WEBSITE OR ITS CONTENT, ANY ERRORS OR OMISSIONS IN CONTENT, DAMAGE FOR LOSS OF BITCOIN OR OTHER CRYPTO ASSETS, LOSS OF BUSINESS, LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION, OR ANY OTHER PECUNIARY OR CONSEQUENTIAL LOSS OR DAMAGE (EVEN IF WE HAVE BEEN NOTIFIED OF SUCH RISK OF LOSS OR DAMAGE).</p>

        <br>

        <h4>NO ADVERTISING</h4>

        <p>Out of respect for the Bitcoin community, this website does not display advertising, and generally does not link to external websites. However, external links are made for references to Bitcoin's technical documentation, for acknowledgements of individual members and open-source projects within the Bitcoin community, and for direct contributors and supporters of <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a>, as follows:</p>
        <ul>
          <li>In order to refer to Bitcoin technical documentation, external links may by added where applicable, to informational websites that respect that Bitcoin community and do not display advertising, altcoins, or third-party trackers, such as <a href="https://en.bitcoin.it/">bitcoin.it</a> and <a href="https://bitcoin.org/">bitcoin.org</a>.</li>
          <br>
          <li>In order to acknowledge individual contributors to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> who have made <a href="https://github.com/mempool/mempool/graphs/contributors">at least 5 contributions visible on GitHub</a>, the contributor's GitHub profile photo will automatically be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to their GitHub profile</u>.</li>
          <br>
          <li>In order to acknowledge members of the Bitcoin community who have made a one-time donation of at least 0.01 BTC to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> on <a href="https://mempool.space/about">mempool.space/about</a>, the Bitcoin community members's Twitter profile photo will automatically be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to their Twitter profile</u>.</li>
          <br>
          <li>In order to acknowledge members of the Bitcoin community who have or have committed to making recurring donations of at least $100 USD per month to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> through <a href="https://github.com/sponsors/mempool">our GitHub Sponsors program</a> for at least 6 months, the Bitcoin community member's GitHub profile photo will automatically be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to their GitHub profile</u>.</li>
          <br>
          <li>In order to acknowledge open source projects that benefit the Bitcoin community and have integrated <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> into their software, the open source project's logo may be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to the project's GitHub repository</u>.</li>
          <br>
          <li>In order to acknowledge federations or alliances within the Bitcoin community that have a relationship to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a>, the federation or alliance logo may be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to the alliance website</u>.</li>
          <br>
          <li>In order to acknowledge the enterprise organizations within the Bitcoin community that have or have committed to making recurring donations of at least $1000 USD per month to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a> through <a href="https://github.com/sponsors/mempool">our GitHub Sponsors program</a> for at least 25 months, the organization's logo may be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to the organization's website</u>.</li>
          <br>
          <li>In order to acknowledge the enterprise organizations within the Bitcoin community that have made a significant one-time donation of at least $25,000 USD to <a href="https://github.com/mempool/mempool">The Mempool Open Source Project</a>, the organization's logo may be displayed on <a href="https://mempool.space/about">mempool.space/about</a>, with <u>a link to the organization's website</u>.</li>
        </ul>
        <p>However, we reserve the right to remove any links from our website that in our sole discretion are inappropriate. All donations are non-refundable.</p>

        <br>

        <ng-container *ngIf="officialMempoolSpace">

          <h4>NO ALTCOINS</h4>

          <p>The <a href="https://mempool.space/">mempool.space</a> explorer is Bitcoin Only.</p>

          <p>Unfortunately, the <a href="https://liquid.network/">liquid.network</a> and <a href="https://bisq.markets/">bisq.markets</a> explorers cannot be considered Bitcoin Only, as they may display altcoins as part of their core network functionality. We do not endorse any altcoins and encourage you to stay Bitcoin Only. Please see <a href="https://bitcoin-only.com/">bitcoin-only.com</a> for more Bitcoin Only resources.</p>

          <br>

        </ng-container>

        <p>EOF</p>

      </div>

  </div>

  <br><br>

</div>
