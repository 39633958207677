<div *ngIf="showTitle" class="main-title" i18n="dashboard.difficulty-adjustment">Difficulty Adjustment</div>
<div class="card-wrapper">
  <div class="card">
    <div class="card-body more-padding">
      <div class="difficulty-adjustment-container" *ngIf="(isLoadingWebSocket$ | async) === false && (difficultyEpoch$ | async) as epochData; else loadingDifficulty">
        <div class="epoch-progress">
          <svg class="epoch-blocks" height="22px" width="100%" viewBox="0 0 224 9" shape-rendering="crispEdges" preserveAspectRatio="none">
            <defs>
              <linearGradient id="diff-gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="#105fb0" />
                <stop offset="100%" stop-color="#9339f4" />
              </linearGradient>
              <linearGradient id="diff-hover-gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="#2486eb" />
                <stop offset="100%" stop-color="#ae6af7" />
              </linearGradient>
            </defs>
            <rect
              *ngFor="let rect of shapes"
              [attr.x]="rect.x" [attr.y]="rect.y"
              [attr.width]="rect.w" [attr.height]="rect.h"
              class="rect {{rect.status}}"
              [class.hover]="hoverSection && rect.status === hoverSection.status"
              (pointerover)="onHover($event, rect);"
              (pointerout)="onBlur($event);"
            >
              <animate
                *ngIf="rect.status === 'next'"
                attributeType="XML"
                attributeName="fill"
                [attr.values]="'#fff;' + (rect.expected ? '#D81B60' : '#2d3348') + ';#fff'"
                dur="2s"
                repeatCount="indefinite"/>
            </rect>
          </svg>
        </div>
        <div class="difficulty-stats">
          <div class="item">
            <div class="card-text">
              ~<app-time [time]="epochData.timeAvg / 1000" [forceFloorOnTimeIntervals]="['minute']" [fractionDigits]="1"></app-time>
            </div>
            <div class="symbol" i18n="difficulty-box.average-block-time">Average block time</div>
          </div>
          <div class="item">
            <div *ngIf="epochData.remainingBlocks < 1870; else recentlyAdjusted" class="card-text bigger" [ngStyle]="{'color': epochData.colorAdjustments}">
              <span *ngIf="epochData.change > 0; else arrowDownDifficulty" >
                <fa-icon class="retarget-sign" [icon]="['fas', 'caret-up']" [fixedWidth]="true"></fa-icon>
              </span>
              <ng-template #arrowDownDifficulty >
                <fa-icon class="retarget-sign" [icon]="['fas', 'caret-down']" [fixedWidth]="true"></fa-icon>
              </ng-template>
              {{ epochData.change | absolute | number: '1.2-2' }}
              <span class="symbol">%</span>
            </div>
            <ng-template #recentlyAdjusted>
              <div class="card-text">&#8212;</div>
            </ng-template>
            <div class="symbol">
              <span i18n="difficulty-box.previous">Previous</span>:
              <span [ngStyle]="{'color': epochData.colorPreviousAdjustments}">
                <span *ngIf="epochData.previousRetarget > 0; else arrowDownPreviousDifficulty" >
                  <fa-icon class="previous-retarget-sign" [icon]="['fas', 'caret-up']" [fixedWidth]="true"></fa-icon>
                </span>
                <ng-template #arrowDownPreviousDifficulty >
                  <fa-icon class="previous-retarget-sign" [icon]="['fas', 'caret-down']" [fixedWidth]="true"></fa-icon>
                </ng-template>
                {{ epochData.previousRetarget | absolute | number: '1.2-2' }} </span> %
            </div>
          </div>
          <div class="item">
            <div class="card-text"><app-time kind="until" [time]="epochData.estimatedRetargetDate" [fastRender]="true"></app-time></div>
            <div class="symbol">
              {{ epochData.retargetDateString }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingDifficulty>
  <div class="epoch-progress">
    <div class="skeleton-loader"></div>
  </div>
  <div class="difficulty-skeleton loading-container">
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
    <div class="item">
      <div class="card-text">
        <div class="skeleton-loader"></div>
        <div class="skeleton-loader"></div>
      </div>
    </div>
  </div>
</ng-template>

<app-difficulty-tooltip
  *ngIf="hoverSection && (isLoadingWebSocket$ | async) === false && (difficultyEpoch$ | async) as epochData"
  [cursorPosition]="tooltipPosition"
  [status]="hoverSection.status"
  [progress]="epochData"
></app-difficulty-tooltip>