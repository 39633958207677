<div
  #tooltip
  *ngIf="line"
  class="bowtie-graph-tooltip"
  [style.visibility]="line ? 'visible' : 'hidden'"
  [style.left]="tooltipPosition.x + 'px'"
  [style.top]="tooltipPosition.y + 'px'"
>
  <ng-container *ngIf="line.rest; else coinbase">
    <span>{{ line.rest }} </span>
    <ng-container [ngSwitch]="line.type">
      <span *ngSwitchCase="'input'" i18n="transaction.other-inputs">other inputs</span>
      <span *ngSwitchCase="'output'" i18n="transaction.other-outputs">other outputs</span>
    </ng-container>
  </ng-container>

  <ng-template #coinbase>
    <ng-container *ngIf="line.coinbase; else pegin">
      <p>Coinbase</p>
    </ng-container>
  </ng-template>

  <ng-template #pegin>
    <ng-container *ngIf="line.pegin; else pegout">
      <p *ngIf="!isConnector">Peg In</p>
    </ng-container>
  </ng-template>

  <ng-template #pegout>
    <ng-container *ngIf="line.pegout; else normal">
      <p *ngIf="!isConnector">Peg Out</p>
      <p *ngIf="line.value != null"><app-amount [satoshis]="line.value"></app-amount></p>
      <p class="address">
        <app-truncate [text]="line.pegout"></app-truncate>
      </p>
    </ng-container>
  </ng-template>

  <ng-template #normal>
      <p *ngIf="!isConnector">
        <ng-container [ngSwitch]="line.type">
          <span *ngSwitchCase="'input'" i18n="transaction.input">Input</span>
          <span *ngSwitchCase="'output'" i18n="transaction.output">Output</span>
          <span *ngSwitchCase="'fee'" i18n="transaction.fee|Transaction fee">Fee</span>
        </ng-container>
        <span *ngIf="line.type !== 'fee'"> #{{ line.index + 1 }}</span>
      </p>
      <ng-container *ngIf="isConnector && line.txid">
        <p>
          <span i18n="transaction">Transaction</span>&nbsp;
          <app-truncate [text]="line.txid"></app-truncate>
        </p>
          <ng-container [ngSwitch]="line.type">
            <p *ngSwitchCase="'input'"><span i18n="transaction.output">Output</span>&nbsp; #{{ line.vout + 1 }}</p>
            <p *ngSwitchCase="'output'"><span i18n="transaction.input">Input</span>&nbsp; #{{ line.vin + 1 }}</p>
          </ng-container>
      </ng-container>
      <p *ngIf="line.value == null && line.confidential" i18n="shared.confidential">Confidential</p>
      <p *ngIf="line.value != null">
        <ng-template [ngIf]="line.asset && line.asset !== nativeAssetId" [ngIfElse]="defaultOutput">
          <div *ngIf="assetsMinimal && assetsMinimal[line.asset] else assetNotFound">
            <ng-container *ngTemplateOutlet="assetBox; context:{ $implicit: line }"></ng-container>
          </div>
          <ng-template #assetNotFound>
            {{ line.value }} <span class="symbol">{{ line.asset | slice : 0 : 7 }}</span>
          </ng-template>
        </ng-template>
        <ng-template #defaultOutput>
          <app-amount [blockConversion]="blockConversion" [satoshis]="line.value"></app-amount>
        </ng-template>
      </p>
      <p *ngIf="line.type !== 'fee' && line.address" class="address">
        <app-truncate [text]="line.address"></app-truncate>
      </p>
  </ng-template>
</div>

<ng-template #assetBox let-item>
  {{ item.value / pow(10, assetsMinimal[item.asset][3]) | number: '1.' + assetsMinimal[item.asset][3] + '-' + assetsMinimal[item.asset][3] }} <span class="symbol">{{ assetsMinimal[item.asset][1] }}</span>
</ng-template>